<template>
    <div>
        <!-- 汇款凭证对话框 -->
        <el-dialog title="线下汇款后提交凭证" :visible.sync="HKshow" width="60%" :before-close="handleCloseHK" center>
            <div class="pzDialo">
                <div class="l">
                    <div class="title">收款账号信息</div>
                    <div class="con">
                        <div class="ti">开户名称</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.accountName }}</div>
                            <div class="fz cur" @click="copyText(accountInfo.accountName)">复制</div>
                        </div>
                        <div class="ti">开户银行</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.bankName }}</div>
                        </div>
                        <div class="ti">汇款账号</div>
                        <div class="box">
                            <div class="text">{{ accountInfo.accountNumber }}</div>
                            <div class="fz cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                        </div>
                    </div>
                </div>
                <div class="r">
                    <div class="title">提交汇款凭证</div>
                    <div class="con">
                        <div class="ti" style="color: #333;margin-bottom: 5px;">汇款单号<span
                                style="color: #FF2121;margin-left: 3px;">*</span>
                        </div>
                        <div class="box" style="margin-bottom: 10px;">
                            <input type="text" v-model="userPaymentNumber" placeholder="请输入汇款单号" class="input">
                        </div>
                        <div class="ti" style="color: #333;margin-bottom: 5px;">备注</div>
                        <div class="box" style="margin-bottom: 10px;">
                            <textarea placeholder="备注信息" v-model="userPaymentNote"> </textarea>
                        </div>
                        <div class="ti" style="color: #333;display: flex;height: 68px;overflow: hidden;">上传凭证<span
                                style="color: #FF2121;margin-left: 3px;">*</span>

                            <div style="margin-left: 10px;width: 258px;">
                                <el-upload action="https://api.cnc-x.cn/mall/uploads" :data="{ type: '2' }" :limit="3"
                                    list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove" :on-change="handleChange">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :modal="false" :visible.sync="dialogVisibleUrl">
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="HKshowClick">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
import { getSubmiteHK } from "@/utils/api/myApi/index"

export default {
    props: ['accountInfo', 'HKshow', 'oid'],
    data() {
        return {
            // 上传凭证
            dialogImageUrl: '',
            dialogVisibleUrl: false,
            userPaymentProof: '', // 图片
            userPaymentNote: '', // 汇款备注
            userPaymentNumber: '', // 汇款单号
            // ---
        }
    },
    methods: {
        // 上传凭证
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.handleChange(file, fileList)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleUrl = true;
        },
        handleChange(file, fileList) {
            this.userPaymentProof = fileList.map(f => f.response?.data || f.url).join(',')
            console.log(fileList, this.userPaymentProof)
        },
        // 确定上传汇款凭证
        async HKshowClick() {
            if (!this.userPaymentNumber) return this.$message.error('请输入汇款单号')
            if (!this.userPaymentProof) return this.$message.error('请上传汇款凭证')
            let data = [
                {
                    userPaymentNumber: this.userPaymentNumber,
                    userPaymentNote: this.userPaymentNote,
                    userPaymentProof: this.userPaymentProof,
                    oid: this.oid,
                    payType: 'HK',
                    ostatus: 'DZF1'
                }
            ]
            const res = await getSubmiteHK(data)
            if (res.data) {
                // this.HKshow = false
                this.$message.success('提交成功')
                setTimeout(() => {
                    if (this.$route.path == "/myIndex/myOrder") {
                        this.$emit('HKshowCl')
                    } else {
                        this.$router.push('/myIndex/myOrder?actMyNum=0')
                    }
                }, 1500);
            }
        },
        // 复制
        copyText(shipNo) {
            const textToCopy = shipNo;
            const clipboard = new Clipboard('.el-button', {
                text: () => textToCopy
            });
            let that = this
            clipboard.on('success', () => {
                that.$message.success('复制成功')
                clipboard.destroy();
            });
            clipboard.on('error', () => {
                that.$message.error('复制失败')
                clipboard.destroy();
            });
            clipboard.onClick(event);
        },
        // 汇款凭证对话框
        handleCloseHK(done) {
            this.$confirm('关闭后可在我的订单页面继续提交')
                .then(_ => {
                    this.$emit('HKshowCl')
                    done();
                })
                .catch(_ => { });
        },
    }
}
</script>

<style lang="less" scoped>
.pzDialo {
    // max-width: 800px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    >div {
        width: 400px;

        >.title {
            font-size: 16px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin-bottom: 15px;
            text-align: center;
        }

        >.con {
            width: 100%;
            height: 250px;
            padding: 15px 21px;
            box-sizing: border-box;
            background: #F3F6F8;
            border-radius: 8px 8px 8px 8px;

            >.ti {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
                margin-bottom: 11px;
            }

            >.box {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                >.input {
                    width: 100%;
                    height: 36px;
                    border-radius: 4px;
                    border: 1px solid #B9B9B9;
                    padding: 0 12px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                }

                >input:focus {
                    border: 1px solid #B9B9B9;
                    outline: none;
                }

                >textarea {
                    width: 100%;
                    height: 52px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #B9B9B9;
                    padding: 7px 12px;
                    box-sizing: border-box;
                    resize: none
                }

                >textarea:focus {
                    border: 1px solid #B9B9B9;
                    outline: none;
                }

                >.text {
                    font-size: 14px;
                    font-family: PingFang SC-Heavy, PingFang SC;
                    font-weight: 800;
                    color: #333333;
                }

                >.fz {
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    margin-left: 10px;
                }
            }
        }
    }
}

/deep/ .el-upload--picture-card {
    width: 65px;
    height: 65px;
    line-height: 65px;

    >i {
        font-size: 16px;
    }
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 65px;
    height: 65px;
    object-fit: cover;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    object-fit: cover;
}

/deep/ .el-dialog--center .el-dialog__body {
    padding: 25px 24px 10px;
}
</style>
